<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <bookmarks /> -->
      <!-- <search-bar class="d-none d-lg-block" /> -->
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button
        variant="flat-primary"
        pill
        size="sm"
        @click="purgeCache"
      >
        <FeatherIcon icon="RefreshCwIcon" />
        Önbellek Temizle
      </b-button>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BButton,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import UserDropdown from '@/layouts/components/UserDropdown.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BButton,
    // Navbar Components
    DarkToggler,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    purgeCache() {
      this.$store.dispatch('cacheClean/purgeCache')
        .then(response => {
          if (response.success) {
            this.showToast({
              title: 'İşlem Başarılı!',
              icon: 'CheckIcon',
              text: 'Önbellek başarıyla temizlendi.',
              variant: 'success',
            })
          } else {
            this.showToast({
              title: 'Hata!',
              icon: 'XIcon',
              text: 'Bir hata oluştu. Lütfen tekrar deneyin veya destek talebi oluşturun.',
              variant: 'success',
            })
          }
        })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'top-right',
      })
    },
  },
}
</script>
